
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import GhostIcon from "images/icons/ghost.svg";
import styles from "pages/404.module.scss";
import NavigationLayout from "components/layout/NavigationLayout";
import FullButton from "components/buttons/FullButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useRouter } from "next/router";
import { productsRoute } from "common/const";
import utilStyles from "styles/utils.module.scss";
import useTranslation from "next-translate/useTranslation";
import parse from 'html-react-parser';
const Custom404 = () => {
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);
    const iconSize = isLarge ? 80 : 60;
    const { t } = useTranslation('common');
    const router = useRouter();
    return (<div className={styles.container}>
            <GhostIcon width={iconSize} height={iconSize} viewBox="0 0 80 80" className={styles.icon}/>
            <span className={styles.title}>{t('404.title')}</span>
            <span className={styles.description}>{parse(t('404.description'))}</span>
            <div style={{ width: isMobile ? 128 : 168, marginTop: 60 }}>
                <FullButton height={isMobile ? 40 : 60} fontSize={16} title={t('404.button')} onClick={() => {
            router.replace({ pathname: productsRoute });
        }}/>
            </div>
            {isMobile && <div style={{ height: 60 }}/>}
        </div>);
};
Custom404.getLayout = NavigationLayout;
export default Custom404;

    async function __Next_Translate__getStaticProps__194f8eada5f__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194f8eada5f__ as getStaticProps }
  